import {defaultState, IFlashMessages} from './state'
import {ADD_FLASH_MESSAGE, CLEAR_FLASH_MESSAGES, REMOVE_FLASH_MESSAGE} from "./types";

const reducer = (state: IFlashMessages = defaultState, action: any): IFlashMessages => {
  switch (action.type) {
    case ADD_FLASH_MESSAGE:
      const messages = state.messages || [];
      messages.push(action.payload);

      return {...state, messages: messages};

    case REMOVE_FLASH_MESSAGE:
      return {...state, messages: state.messages.filter((message) => message.id !== action.payload)};

    case CLEAR_FLASH_MESSAGES:
      return {...state, messages: null };
  }

  return state
}

export default reducer
