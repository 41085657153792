import React from 'react'
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import store from '../store'
import apiClient from '@mpe/api-client'
import LoginLayout from '../layouts/LoginLayout'
import DefaultLayout from '../layouts/DefaultLayout'
import AuthGuard from '../components/guards/AuthGuard'

import '../styles/app.scss'

apiClient.setConfig({
  url: process.env.NEXT_PUBLIC_API_URL ?? 'http://localhost',
  s3Url: process.env.NEXT_PUBLIC_S3_URL ?? 'http://localhost:9000/mpe',
  s3PhotoRewrite: process.env.NEXT_PUBLIC_S3_PHOTO_REWRITE ?? null,
})

function App({ Component, pageProps, router }: AppProps) {
  const isLoginRoute = '/login' === router.route
  const LayoutComponent: any = isLoginRoute ? LoginLayout : DefaultLayout

  return (
    <Provider store={store}>
      <AuthGuard>
        <LayoutComponent>
          <Component {...pageProps} />
        </LayoutComponent>
      </AuthGuard>
    </Provider>
  )
}

export default App
