export interface IPageEditorState {
  status: string
  error?: string
  content?: IPageData
}

export interface IPageData {
  id: number
  title: string
  alias: string
  data?: []
}

export const defaultState: IPageEditorState = {
  status: 'unloaded',
  content: null,
  error: null
}
