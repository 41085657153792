export interface IFlashMessage {
  id: string,
  type: 'info' | 'error' | 'success',
  message: string,
}

export interface IFlashMessages {
  messages?: IFlashMessage[]
}

export const defaultState: IFlashMessages = {
  messages: null
}
