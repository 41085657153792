import { defaultState, IMediaUploaderState, IUploadFileProgress } from './state'
import {
  ADD_UPLOAD_FILES,
  RESET_UPLOAD_FILES,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_UPLOAD_PROGRESS
} from './types'

let items = []

const reducer = (state: IMediaUploaderState = defaultState, action: any): IMediaUploaderState => {
  switch (action.type) {
    case ADD_UPLOAD_FILES:
      return { ...state, uploadFiles: { ...state.uploadFiles, items: [...action.items, ...state.uploadFiles.items] } }

    case RESET_UPLOAD_FILES:
      return { ...state, uploadFiles: { items: [] } }

    case UPLOAD_FILE_SUCCESS:
      const appendedItems = state.uploadFiles.items.map((value: IUploadFileProgress) => {
        if (action.item === value.item) {
          return {
            ...value,
            entity: action.entity,
          }
        }

        return value
      })

      return { ...state, uploadFiles: { items: [...appendedItems] } }

    case UPLOAD_FILE_ERROR:
      const clearedItems = [...state.uploadFiles.items];
      state.uploadFiles.items.find((value: IUploadFileProgress, index) => {
        if (action.item === value.item) {
          clearedItems.splice(index, 1);

          return true
        }

        return false
      })

      return { ...state, uploadFiles: { items: [...clearedItems] } }

    case UPLOAD_FILE_UPLOAD_PROGRESS:
      let uploadingItems = state.uploadFiles.items.map((value: IUploadFileProgress) => {
        if (action.item === value.item) {
          return {
            ...value,
            progress: {
              ...value.progress,
              ...action.progress,
            },
          }
        }

        return value
      })

      return { ...state, uploadFiles: { items: [...uploadingItems] } }
  }

  return state
}

export default reducer
