export const UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST = 'UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST'
export const UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS = 'UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS'
export const UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR = 'UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR'

export const DELETE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST = 'DELETE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST'
export const DELETE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS = 'DELETE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS'
export const DELETE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR = 'DELETE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR'

export const RESET_LOADED_ENTITY_CHANGE_REQUEST = 'RESET_LOADED_ENTITY_CHANGE_REQUEST'

export const FETCH_ENTITY_CHANGE_REQUESTS_REQUEST = 'FETCH_ENTITY_CHANGE_REQUESTS_REQUEST'
export const FETCH_ENTITY_CHANGE_REQUESTS_SUCCESS = 'FETCH_ENTITY_CHANGE_REQUESTS_SUCCESS'
export const FETCH_ENTITY_CHANGE_REQUESTS_ERROR = 'FETCH_ENTITY_CHANGE_REQUESTS_ERROR'

export const FETCH_ENTITY_CHANGE_REQUEST_HISTORY_REQUEST = 'FETCH_ENTITY_CHANGE_REQUEST_HISTORY_REQUEST'
export const FETCH_ENTITY_CHANGE_REQUEST_HISTORY_SUCCESS = 'FETCH_ENTITY_CHANGE_REQUEST_HISTORY_SUCCESS'
export const FETCH_ENTITY_CHANGE_REQUEST_HISTORY_ERROR = 'FETCH_ENTITY_CHANGE_REQUEST_HISTORY_ERROR'

export const FETCH_CONNECTION_HISTORY_REQUEST = 'FETCH_CONNECTION_HISTORY_REQUEST'
export const FETCH_CONNECTION_HISTORY_SUCCESS = 'FETCH_CONNECTION_HISTORY_SUCCESS'
export const FETCH_CONNECTION_HISTORY_ERROR = 'FETCH_CONNECTION_HISTORY_ERROR'
