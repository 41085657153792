import apiClient from '@mpe/api-client'
import { Dispatch } from 'redux'
import { FETCH_USER_ERROR, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, RESET_AUTH } from './types'

export const fetchUser = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_USER_REQUEST })

  if (!localStorage.getItem('token')) {
    dispatch({ type: RESET_AUTH })

    return
  }

  try {
    const user = await apiClient.getCurrentUser()

    if (!user) {
      dispatch({ type: FETCH_USER_ERROR })

      return
    }

    dispatch({ type: FETCH_USER_SUCCESS, user })

    return user

  } catch (err) {
    dispatch({ type: FETCH_USER_ERROR })
  }
}

export const logout = () => async (dispatch: Dispatch) => {
  await apiClient.logout()

  dispatch({ type: RESET_AUTH })
}
