import { defaultState, IVideoDataSourceState } from './state'
import {
  FETCH_ITEMS_ERROR,
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  RESET_SEARCH_VIDEO,
  TOGGLE_VIDEO_SEARCH_POPUP,
  UPDATE_SEARCH_VIDEO,
} from './types'

const reducer = (state: IVideoDataSourceState = defaultState, action: any): IVideoDataSourceState => {
  switch (action.type) {
    case TOGGLE_VIDEO_SEARCH_POPUP:
      return { ...state, popup: { isVisible: !state.popup.isVisible } }

    case UPDATE_SEARCH_VIDEO:
      return { ...state, item: action.item }

    case RESET_SEARCH_VIDEO:
      return { ...state, item: null }

    case FETCH_ITEMS_REQUEST:
      return { ...state }

    case FETCH_ITEMS_SUCCESS:
      return { ...state }

    case FETCH_ITEMS_ERROR:
      return { ...state }
  }

  return state
}

export default reducer
