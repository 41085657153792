export const FETCH_CONNECTIONS_REQUEST = 'FETCH_CONNECTIONS_REQUEST'
export const FETCH_CONNECTIONS_SUCCESS = 'FETCH_CONNECTIONS_SUCCESS'
export const FETCH_CONNECTIONS_ERROR = 'FETCH_CONNECTIONS_ERROR'

export const FETCH_CHANGE_REQUEST_CONNECTIONS_REQUEST = 'FETCH_CHANGE_REQUEST_CONNECTIONS_REQUEST'
export const FETCH_CHANGE_REQUEST_CONNECTIONS_SUCCESS = 'FETCH_CHANGE_REQUEST_CONNECTIONS_SUCCESS'
export const FETCH_CHANGE_REQUEST_CONNECTIONS_ERROR = 'FETCH_CHANGE_REQUEST_CONNECTIONS_ERROR'

export const FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_REQUEST = 'FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_REQUEST'
export const FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_SUCCESS = 'FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_SUCCESS'
export const FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_ERROR = 'FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_ERROR'

export const REPLACE_CHANGE_REQUEST_CONNECTION_ITEMS = 'REPLACE_CHANGE_REQUEST_CONNECTION_ITEMS'

export const FETCH_MEDIA_CONNECTIONS_REQUEST = 'FETCH_MEDIA_CONNECTIONS_REQUEST'
export const FETCH_MEDIA_CONNECTIONS_SUCCESS = 'FETCH_MEDIA_CONNECTIONS_SUCCESS'
export const FETCH_MEDIA_CONNECTIONS_ERROR = 'FETCH_MEDIA_CONNECTIONS_ERROR'
export const REPLACE_MEDIA_CONNECTION_ITEMS = 'REPLACE_MEDIA_CONNECTION_ITEMS'

export const DELETE_CONNECTION_REQUEST = 'DELETE_CONNECTION_REQUEST'
export const DELETE_CONNECTION_SUCCESS = 'DELETE_CONNECTION_SUCCESS'
export const DELETE_CONNECTION_ERROR = 'DELETE_CONNECTION_ERROR'

export const DELETE_MEDIA_CONNECTION_REQUEST = 'DELETE_MEDIA_CONNECTION_REQUEST'
export const DELETE_MEDIA_CONNECTION_SUCCESS = 'DELETE_MEDIA_CONNECTION_SUCCESS'
export const DELETE_MEDIA_CONNECTION_ERROR = 'DELETE_MEDIA_CONNECTION_ERROR'

export const UPDATE_CONNECTION_REQUEST = 'UPDATE_CONNECTION_REQUEST'
export const UPDATE_CONNECTION_SUCCESS = 'UPDATE_CONNECTION_SUCCESS'
export const UPDATE_CONNECTION_ERROR = 'UPDATE_CONNECTION_ERROR'

export const SAVE_CHANGE_REQUEST_CONNECTIONS_ORDER_REQUEST = 'SAVE_CHANGE_REQUEST_CONNECTIONS_ORDER_REQUEST'
export const SAVE_CHANGE_REQUEST_CONNECTIONS_ORDER_SUCCESS = 'SAVE_CHANGE_REQUEST_CONNECTIONS_ORDER_SUCCESS'
export const SAVE_CHANGE_REQUEST_CONNECTIONS_ORDER_ERROR = 'SAVE_CHANGE_REQUEST_CONNECTIONS_ORDER_ERROR'

export const UPDATE_CONNECTION_EDITOR_ITEM = 'UPDATE_CONNECTION_EDITOR_ITEM'

export const RESET_LOADED_CONNECTIONS = 'RESET_LOADED_CONNECTIONS'
export const RESET_LOADED_MEDIA_CONNECTIONS = 'RESET_LOADED_MEDIA_CONNECTIONS'
export const RESET_LOADED_CHANGE_REQUEST_CONNECTIONS = 'RESET_LOADED_CHANGE_REQUEST_CONNECTIONS'
export const RESET_LOADED_CHANGE_REQUEST_MEDIA_CONNECTIONS = 'RESET_LOADED_CHANGE_REQUEST_MEDIA_CONNECTIONS'
