export default {
  Award: 'fa-trophy',
  Band: 'fa-guitar',
  Person: 'fa-user-alt',
  Tag: 'fa-tag',
  MusicalPeriod: 'fa-sliders-h',
  Event: 'fa-calendar-day',
  EventSeries: 'fa-calendar-alt',
  Venue: 'fa-map-marker-alt',
  Institution: 'fa-door-open',
  Publication: 'fa-newspaper',
  PublicationItem: 'fa-th-large',
  Song: 'fa-music',
  MusicAlbum: 'fa-compact-disc',
  Album: 'fa-folder-open',
  Audio: 'fa-file-audio',
  Image: 'fa-file-image',
  Video: 'fa-file-video',
  Reference: 'fa-book-open',
}
