export default [
  {
    value: 'pending',
    name: 'Nem publikált',
  },

  {
    value: 'published',
    name: 'Publikált',
  },

  {
    value: 'cloning',
    name: 'Másolás...',
  },
]
