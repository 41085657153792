import { combineReducers } from 'redux'
import entityManagerReducer from '../../components/entity-manager/store/reducer'
import entityChangeRequestsReducer from '../../components/entity-change-requests/store/reducer'
import mediaUploaderReducer from '../../components/media-uploader/store/reducer'
import connectionsReducer from '../../components/connections/store/reducer'
import videoSegmentManagerReducer from '../../components/video-segment-manager/store/reducer'
import videoDataSourceReducer from '../../components/video-data-source/store/reducer'
import authReducer from '../../components/auth/store/reducer'
import usersDataReducer from '../../components/users/store/reducer'
import pageDataReducer from '../../components/pages/store/reducer'
import flashMessages from "../../components/flash-messages/store/reducer";

const rootReducer = combineReducers({
  entityChangeRequests: entityChangeRequestsReducer,
  entityManager: entityManagerReducer,
  mediaUploader: mediaUploaderReducer,
  connections: connectionsReducer,
  videoSegmentManager: videoSegmentManagerReducer,
  videoDataSource: videoDataSourceReducer,
  usersData: usersDataReducer,
  pageData: pageDataReducer,
  auth: authReducer,
  flash: flashMessages,
})

export default rootReducer
