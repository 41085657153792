import React, { PropsWithChildren } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../store'
import { fetchUser } from '../auth/store/actions'
import { withRouter } from 'next/router'
import { WithRouterProps } from 'next/dist/client/with-router'
import { IAuthState } from '../auth/store/state'
import apiClient from '@mpe/api-client'
import axios from 'axios'
import Loader from "../common/Loader";

interface Props extends WithRouterProps {
  auth: IAuthState
  fetchUser: any
}

class AuthGuard extends React.Component<PropsWithChildren<Props>> {
  get isLoginRoute() {
    return '/login' === this.props.router.route
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem('token')

    if (jwtToken) {
      apiClient.setJwtToken(jwtToken)

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken
    }

    this.props.fetchUser()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    // redirect to login route
    if (!this.props.auth.isLoading && !this.props.auth.isLoggedIn) {
      this.invalidateAndRedirect()

      return
    }

    if (
      !this.props.auth.isLoading
      && !this.props.auth.user?.roles.includes('ROLE_ADMIN')
      && !this.props.auth.user?.roles.includes('ROLE_EDITOR')
      && !this.props.auth.user?.roles.includes('ROLE_PUBLISHER')
    ) {
      this.invalidateAndRedirect()
    }
  }

  render() {
    if (this.props.auth.isLoading) {
      return <Loader wrapperClass="spinner-large" label="Oldal betöltése..." />
    }

    if (!this.isLoginRoute && !this.props.auth.isLoggedIn) {
      return null
    }

    if (
      !this.isLoginRoute
      && !this.props.auth.user?.roles.includes('ROLE_ADMIN')
      && !this.props.auth.user?.roles.includes('ROLE_EDITOR')
      && !this.props.auth.user?.roles.includes('ROLE_PUBLISHER')
    ) {
      return null
    }

    return this.props.children
  }

  invalidateAndRedirect() {
    localStorage.removeItem('token')
    apiClient.clearJwtToken()

    if (!this.isLoginRoute) {
      this.props.router.push('/login')
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  fetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthGuard))
