import { IVideoSegment } from '@mpe/api-client/models/entities/video'

export interface IVideoSegmentManagerState {
  update: {
    status: string
  }

  editor: {
    item: IVideoSegment | null
  }
}

export const defaultState: IVideoSegmentManagerState = {
  update: {
    status: 'unloaded',
  },

  editor: {
    item: null,
  },
}
