import { defaultState, IPageEditorState } from './state'
import { FETCH_PAGE_EDITOR_DATA_REQUEST, FETCH_PAGE_EDITOR_DATA_ERROR, FETCH_PAGE_EDITOR_DATA_SUCCESS } from './types'

const reducer = (state: IPageEditorState = defaultState, action: any): IPageEditorState => {
  switch (action.type) {
    case FETCH_PAGE_EDITOR_DATA_SUCCESS:
      return {...state, status: 'loaded', content: action.payload};

    case FETCH_PAGE_EDITOR_DATA_REQUEST:
      return {...state, status: 'loading', content: null };

    case FETCH_PAGE_EDITOR_DATA_ERROR:
      return {...state, status: 'error', error: action.payload.message || action.payload.toString() };
  }

  return state
}

export default reducer
