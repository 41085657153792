import { User } from '@mpe/api-client/models/user'

export interface IAuthState {
  isLoading: boolean
  isLoggedIn: boolean
  user: User
}

export const defaultState: IAuthState = {
  isLoading: true,
  isLoggedIn: false,
  user: null,
}
