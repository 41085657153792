import { Entity } from '@mpe/api-client/models/entities/entity'
import { IPagedEntityCollection } from '@mpe/api-client/types'

export interface IEntityManagerState {
  entity: {
    status: string
    item: Entity<any>
    clone?: Entity<any>
  }

  cloning: {
    status: string
    item?: Entity<any>
    message?: string
  }

  imageEntity: {
    status: string
    item: Entity<any>
  }

  collection: {
    status: string
    data: IPagedEntityCollection | null
  }

  entitySearch: {
    item: Entity<any>
  }
}

export const defaultState: IEntityManagerState = {
  entity: {
    status: 'unloaded',
    item: null,
    clone: null,
  },

  cloning: {
    status: 'unloaded',
    item: null,
  },

  imageEntity: {
    status: 'unloaded',
    item: null,
  },

  collection: {
    status: 'unloaded',
    data: null,
  },

  entitySearch: {
    item: null,
  },
}
