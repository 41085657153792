import Navbar from '../components/layout/Navbar'
import React, { PropsWithChildren } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { IRootState } from '../store'
import { logout } from '../components/auth/store/actions'

interface State {
  isSidebarVisible: boolean
}

const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = {
  logout,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface ComponentProps {
}

type Props = ComponentProps & ConnectedProps<typeof connector>

class DefaultLayout extends React.Component<PropsWithChildren<Props>, State> {
  constructor(props: Readonly<Props> | Props) {
    super(props)

    this.state = {
      isSidebarVisible: false,
    }
  }

  render() {
    return (
      <>
        <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="/">
            MPÉ
          </a>

          <button className="navbar-toggle-sidebar" onClick={() => this.toggleSidebar()}>
            Menü
          </button>

          <ul className="navbar-nav px-3">
            <li className="nav-item text-nowrap">
              <a className="nav-link" onClick={() => this.props.logout()}>
                Kilépés ({this.props.auth.user.email})
              </a>
            </li>
          </ul>
        </nav>

        <div className="container-fluid">
          <div className="row">
            <nav className={`col-md-2 bg-light sidebar${this.state.isSidebarVisible ? ' sidebar--visible' : ''}`}>
              <Navbar/>
            </nav>

            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
              {this.props.children}
            </main>
          </div>
        </div>
      </>
    )
  }

  toggleSidebar() {
    this.setState({ isSidebarVisible: !this.state.isSidebarVisible })
  }
}

export default connector(DefaultLayout)
