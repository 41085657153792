import { defaultState, IVideoSegmentManagerState } from './state'
import {
  UPDATE_VIDEO_SEGMENT_EDITOR_ITEM,
  UPDATE_VIDEO_SEGMENTS_ERROR,
  UPDATE_VIDEO_SEGMENTS_REQUEST,
  UPDATE_VIDEO_SEGMENTS_SUCCESS,
} from './types'

const reducer = (state: IVideoSegmentManagerState = defaultState, action: any): IVideoSegmentManagerState => {
  switch (action.type) {
    case UPDATE_VIDEO_SEGMENTS_REQUEST:
      return { ...state, update: { status: 'loading' } }

    case UPDATE_VIDEO_SEGMENTS_SUCCESS:
      return { ...state, update: { status: 'loaded' } }

    case UPDATE_VIDEO_SEGMENTS_ERROR:
      return { ...state, update: { status: 'error' } }

    case UPDATE_VIDEO_SEGMENT_EDITOR_ITEM:
      return { ...state, editor: { item: action.item } }
  }

  return state
}

export default reducer
