import { IPagedEntityChangeRequestCollection, IPagedModelCollection } from '@mpe/api-client/types'
import { EntityChangeRequest } from '@mpe/api-client/models/change-requests/entity-change-request'

export interface IEntityChangeRequestsState {
  item: {
    status: string
    data: EntityChangeRequest<any>

    updating: {
      status: string
    }
  }

  collection: {
    status: string
    data: IPagedEntityChangeRequestCollection | null
  }

  historyCollection: {
    status: string
    data: IPagedEntityChangeRequestCollection | null
  }

  connectionHistoryCollection: {
    status: string
    data: IPagedModelCollection | null
  }
}

export const defaultState: IEntityChangeRequestsState = {
  item: {
    status: 'unloaded',
    data: null,

    updating: {
      status: 'unloaded',
    },
  },

  collection: {
    status: 'unloaded',
    data: null,
  },

  historyCollection: {
    status: 'unloaded',
    data: null,
  },

  connectionHistoryCollection: {
    status: 'unloaded',
    data: null,
  },
}
