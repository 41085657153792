import { Connection } from "@mpe/api-client/models/connections/connection";
import { defaultState, IConnectionsState } from "./state";
import {
  DELETE_CONNECTION_SUCCESS,
  DELETE_MEDIA_CONNECTION_SUCCESS,
  FETCH_CHANGE_REQUEST_CONNECTIONS_ERROR,
  FETCH_CHANGE_REQUEST_CONNECTIONS_REQUEST,
  FETCH_CHANGE_REQUEST_CONNECTIONS_SUCCESS,
  FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_ERROR,
  FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_REQUEST,
  FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_ERROR,
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_MEDIA_CONNECTIONS_ERROR,
  FETCH_MEDIA_CONNECTIONS_REQUEST,
  FETCH_MEDIA_CONNECTIONS_SUCCESS,
  REPLACE_CHANGE_REQUEST_CONNECTION_ITEMS,
  REPLACE_MEDIA_CONNECTION_ITEMS,
  RESET_LOADED_CHANGE_REQUEST_CONNECTIONS,
  RESET_LOADED_CHANGE_REQUEST_MEDIA_CONNECTIONS,
  RESET_LOADED_CONNECTIONS,
  UPDATE_CONNECTION_EDITOR_ITEM,
  UPDATE_CONNECTION_ERROR,
  UPDATE_CONNECTION_REQUEST,
  UPDATE_CONNECTION_SUCCESS,
} from "./types";

const getFilteredConnections = (state: IConnectionsState, item: Connection) => {
  return state.connections.items.filter(
    (connection: Connection) => connection.id !== item.id
  );
};

const getFilteredMediaConnections = (
  state: IConnectionsState,
  item: Connection
) => {
  return state.mediaConnections.items.filter(
    (connection: Connection) => connection.id !== item.id
  );
};

const reducer = (
  state: IConnectionsState = defaultState,
  action: any
): IConnectionsState => {
  switch (action.type) {
    case RESET_LOADED_CONNECTIONS:
      return {
        ...state,
        connections: { status: "unloaded", items: [] },
      };

    case RESET_LOADED_CHANGE_REQUEST_CONNECTIONS:
      return {
        ...state,
        changeRequestConnections: { status: "unloaded", items: [] },
      };

    case RESET_LOADED_CHANGE_REQUEST_MEDIA_CONNECTIONS:
      return {
        ...state,
        changeRequestMediaConnections: { status: "unloaded", items: [] },
      };

    case FETCH_CONNECTIONS_REQUEST: {
      const pageData = state.connections.pageData;
      return {
        ...state,
        connections: { status: "loading", items: [], pageData },
      };
    }

    case FETCH_CONNECTIONS_SUCCESS: {
      const { items, pageData } = action;
      return {
        ...state,
        connections: { status: "loaded", items: items ?? [], pageData },
      };
    }
    case FETCH_CONNECTIONS_ERROR:
      return {
        ...state,
        connections: { status: "error", items: [] },
      };

    case FETCH_CHANGE_REQUEST_CONNECTIONS_REQUEST:
      return {
        ...state,
        changeRequestConnections: {
          status: "loading",
          items: [],
          pageData: state.changeRequestConnections.pageData,
        },
      };

    case FETCH_CHANGE_REQUEST_CONNECTIONS_SUCCESS:
      return {
        ...state,
        changeRequestConnections: {
          status: "loaded",
          items: action.items ?? [],
          pageData: action.pageData,
        },
      };

    case FETCH_CHANGE_REQUEST_CONNECTIONS_ERROR:
      return {
        ...state,
        changeRequestConnections: { status: "error", items: [] },
      };

    case FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_REQUEST:
      return {
        ...state,
        changeRequestMediaConnections: {
          status: "loading",
          items: [],
          pageData: state.changeRequestMediaConnections.pageData,
        },
      };

    case FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_SUCCESS:
      return {
        ...state,
        changeRequestMediaConnections: {
          status: "loaded",
          items: action.items ?? [],
          pageData: action.pageData,
        },
      };

    case FETCH_CHANGE_REQUEST_MEDIA_CONNECTIONS_ERROR:
      return {
        ...state,
        changeRequestMediaConnections: { status: "error", items: [] },
      };

    case REPLACE_CHANGE_REQUEST_CONNECTION_ITEMS:
      return {
        ...state,
        changeRequestConnections: {
          status: "loaded",
          items: [...action.items],
        },
      };

    case FETCH_MEDIA_CONNECTIONS_REQUEST: {
      const pageData = state.mediaConnections.pageData;
      return {
        ...state,
        mediaConnections: {
          status: "loading",
          items: [],
          pageData,
        },
      };
    }

    case FETCH_MEDIA_CONNECTIONS_SUCCESS: {
      const { items, pageData } = action;
      return {
        ...state,
        mediaConnections: {
          status: "loaded",
          items: items ?? [],
          pageData,
        },
      };
    }
    case FETCH_MEDIA_CONNECTIONS_ERROR:
      return {
        ...state,
        mediaConnections: { status: "error", items: [] },
      };

    case REPLACE_MEDIA_CONNECTION_ITEMS:
      return {
        ...state,
        mediaConnections: { status: "loaded", items: [...action.items] },
      };

    case DELETE_CONNECTION_SUCCESS:
      return {
        ...state,
        connections: {
          status: "loaded",
          items: [...getFilteredConnections(state, action.connection)],
        },
      };

    case DELETE_MEDIA_CONNECTION_SUCCESS:
      return {
        ...state,
        mediaConnections: {
          status: "loaded",
          items: [...getFilteredMediaConnections(state, action.connection)],
        },
      };

    case UPDATE_CONNECTION_EDITOR_ITEM:
      return {
        ...state,
        connectionEditor: {
          item: action.connection,
          update: { status: "unloaded" },
        },
      };

    case UPDATE_CONNECTION_REQUEST:
      return {
        ...state,
        connectionEditor: {
          ...state.connectionEditor,
          update: { status: "loading" },
        },
      };

    case UPDATE_CONNECTION_ERROR:
      return {
        ...state,
        connectionEditor: {
          ...state.connectionEditor,
          update: { status: "error" },
        },
      };

    case UPDATE_CONNECTION_SUCCESS:
      return {
        ...state,
        connectionEditor: {
          ...state.connectionEditor,
          update: { status: "loaded" },
        },
      };
  }

  return state;
};

export default reducer;
