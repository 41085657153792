import { User } from '@mpe/api-client/models/user'
import {IPagedModelCollection} from "@mpe/api-client/types";

export interface IUsersDataState {
  status: string
  current?: User
  list: IPagedModelCollection | null
  search: {}
  error?: string
}

export const defaultState: IUsersDataState = {
  status: 'unloaded',
  current: null,
  list: null,
  search: {},
  error: null
}
