import { Movie } from '@mpe/api-client/models/movie'

export interface IVideoDataSourceState {
  popup: {
    isVisible: boolean
  }

  items: {
    status: string
    items: Movie[]
  }

  item: Movie | null
}

export const defaultState: IVideoDataSourceState = {
  popup: {
    isVisible: false,
  },

  items: {
    status: 'unloaded',
    items: [],
  },

  item: null,
}
