import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import { WithRouterProps } from 'next/dist/client/with-router'

import { getEntityTypeLabel } from "../../utils/entityHelper";
import EntityIcon from '../common/EntityIcon'

import { IRootState } from '../../store'
import { IAuthState } from '../auth/store/state'

interface Props extends WithRouterProps {
  auth: IAuthState
}

class Navbar extends React.Component<Props> {
  primaryNav = [
    'Band',
    'Person',
    'Tag',
    'MusicalPeriod',
    'Event',
    'EventSeries',
    'Venue',
    'Institution',
    'Publication',
    'PublicationItem',
    'MusicAlbum',
    'Song',
    'Award',
    'Reference',
  ]

  secondaryNav = [
    'Album',
    'Audio',
    'Image',
    'Video',
  ]

  render() {
    return (
      <div className="sidebar-sticky">
        <ul className="nav flex-column">
          {this.props.auth.user.isApprover() && this.renderEntityBulkApproveLink()}
          {this.primaryNav.map((name: string, key: number) => this.renderNavigationLink(name, key))}
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Média</span>
        </h6>

        <ul className="nav flex-column mb-2">
          {this.secondaryNav.map((name: string, key: number) => this.renderNavigationLink(name, key))}
        </ul>

        {this.props.auth.user.hasRole('ROLE_ADMIN') && (
          <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Adminisztrátor</span>
            </h6>

            <ul className="nav flex-column mb-2">
              {this.renderAdminSection()}
            </ul>
          </>
        )}
      </div>
    )
  }

  renderEntityBulkApproveLink() {
    return (
      <li className="nav-item nav-item--institution">
        <Link href={`/entity-change-requests`}>
          <a className={`nav-link${'/entity-change-requests' === this.props.router.asPath ? ' active' : ''}`}>
            <i className="fas fa-thumbs-up entity-color--institution"/>

            <span>Tömeges entitás jóváhagyás</span>
          </a>
        </Link>
      </li>
    )
  }

  renderAdminSection() {
    return <>
      <li className="nav-item nav-item--create-user mt-2">
        <Link href={`/users`}>
          <a className={`nav-link${'/users' === this.props.router.asPath ? ' active' : ''}`}>
            <span>Felhasználók</span>
          </a>
        </Link>
      </li>
      <li className="nav-item nav-item--edit-homepage mt-2">
        <Link href={`/pages/homepage`}>
          <a className={`nav-link${'/pages/homepage' === this.props.router.asPath ? ' active' : ''}`}>
            <span>Főoldal</span>
          </a>
        </Link>
      </li>
      <li className="nav-item nav-item--edit-homepage mt-2">
        <Link href={`/llm`}>
          <a className={`nav-link${'/llm' === this.props.router.asPath ? ' active' : ''}`}>
            <span>LLM beállítások</span>
          </a>
        </Link>
      </li>
    </>
  }

  renderNavigationLink(entityName: string, key: number) {
    return (
      <li key={key} className={`nav-item nav-item--${entityName.toLocaleLowerCase()}`}>
        <Link href={`/entities?type=${entityName}`}>
          <a className={`nav-link${this.isRouteActive(entityName) ? ' active' : ''}`}>
            <EntityIcon entityType={entityName}/>

            <span>{getEntityTypeLabel(entityName) ?? ''}</span>
          </a>
        </Link>
      </li>
    )
  }

  isRouteActive(route: string): boolean {
    return route === this.props.router.query?.type
  }
}

const mapStateToProps = (state: IRootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar))
