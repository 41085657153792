import React from 'react'

function LoginLayout({children}: {children: React.ReactNode}) {
  return (
    <>
      {children}
    </>
  )
}

export default LoginLayout
