import {defaultState, IUsersDataState} from './state'
import {
  FETCH_USER_DATA_REQUEST, FETCH_USER_DATA_SUCCESS, FETCH_USER_DATA_ERROR,
  FETCH_USERS_LIST_REQUEST, FETCH_USERS_LIST_SUCCESS, FETCH_USERS_LIST_ERROR,
} from './types'


const reducer = (state: IUsersDataState = defaultState, action: any): IUsersDataState => {
  switch (action.type) {
    case FETCH_USER_DATA_SUCCESS:
      return {...state, status: 'loaded', current: action.payload};

    case FETCH_USERS_LIST_SUCCESS:
      return {...state, status: 'loaded', list: action.payload};

    case FETCH_USER_DATA_REQUEST:
    case FETCH_USERS_LIST_REQUEST:
      return {...state, status: 'loading', list: null };

    case FETCH_USER_DATA_ERROR:
    case FETCH_USERS_LIST_ERROR:
      return {...state, status: 'error', error: action.payload.message || action.payload.toString() };
  }

  return state
}

export default reducer
