export default {
  Award: { plural: 'Díjak', singular: 'Díj'},
  Song: { plural: 'Dalok', singular: 'Dal' },
  Event: { plural: 'Események', singular: 'Esemény' },
  EventSeries: { plural: 'Eseménysorozatok', singular: 'Eseménysorozat' },
  Venue: { plural: 'Helyszínek', singular: 'Helyszín' },
  Institution: { plural: 'Intézmények', singular: 'Intézmény' },
  Publication: { plural: 'Kiadványok', singular: 'Kiadvány' },
  PublicationItem: { plural: 'Kiadvány elemek', singular: 'Kiadvány elem' },
  Person: { plural: 'Személyek', singular: 'Személy' },
  MusicalPeriod: { plural: 'Zenei korszakok', singular: 'Zenei korszak' },
  Tag: { plural: 'Címkék', singular: 'Címke' },
  Band: { plural: 'Előadók', singular: 'Előadó' },
  Album: { plural: 'Média albumok', singular: 'Média album' },
  MusicAlbum: { plural: 'Albumok', singular: 'Album' },
  Image: { plural: 'Képek', singular: 'Kép' },
  Audio: { plural: 'Audio fájlok', singular: 'Audio fájl' },
  Video: { plural: 'Videók', singular: 'Videó' },
  Entity: { plural: 'Entitások', singular: 'Entitás' },
  Reference: { plural: 'Hivatkozások', singular: 'Hivatkozás' },
}
