import { defaultState, IEntityChangeRequestsState } from './state'
import {
  FETCH_CONNECTION_HISTORY_ERROR,
  FETCH_CONNECTION_HISTORY_REQUEST,
  FETCH_CONNECTION_HISTORY_SUCCESS,
  FETCH_ENTITY_CHANGE_REQUEST_HISTORY_ERROR,
  FETCH_ENTITY_CHANGE_REQUEST_HISTORY_REQUEST,
  FETCH_ENTITY_CHANGE_REQUEST_HISTORY_SUCCESS,
  FETCH_ENTITY_CHANGE_REQUESTS_ERROR,
  FETCH_ENTITY_CHANGE_REQUESTS_REQUEST,
  FETCH_ENTITY_CHANGE_REQUESTS_SUCCESS,
  RESET_LOADED_ENTITY_CHANGE_REQUEST,
  UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR,
  UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST,
  UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS,
} from './types'

const reducer = (state: IEntityChangeRequestsState = defaultState, action: any): IEntityChangeRequestsState => {
  switch (action.type) {
    case FETCH_ENTITY_CHANGE_REQUESTS_REQUEST:
      return { ...state, collection: { status: 'loading', data: null } }

    case FETCH_ENTITY_CHANGE_REQUESTS_SUCCESS:
      return { ...state, collection: { status: 'loaded', data: { ...action.payload } } }

    case FETCH_ENTITY_CHANGE_REQUESTS_ERROR:
      return { ...state, collection: { status: 'error', data: null } }

    case FETCH_ENTITY_CHANGE_REQUEST_HISTORY_REQUEST:
      return { ...state, historyCollection: { status: 'loading', data: null } }

    case FETCH_ENTITY_CHANGE_REQUEST_HISTORY_SUCCESS:
      return { ...state, historyCollection: { status: 'loaded', data: { ...action.payload } } }

    case FETCH_ENTITY_CHANGE_REQUEST_HISTORY_ERROR:
      return { ...state, historyCollection: { status: 'error', data: null } }

    case FETCH_CONNECTION_HISTORY_REQUEST:
      return { ...state, connectionHistoryCollection: { status: 'loading', data: null } }

    case FETCH_CONNECTION_HISTORY_SUCCESS:
      return { ...state, connectionHistoryCollection: { status: 'loaded', data: { ...action.payload } } }

    case FETCH_CONNECTION_HISTORY_ERROR:
      return { ...state, connectionHistoryCollection: { status: 'error', data: null } }

    case RESET_LOADED_ENTITY_CHANGE_REQUEST:
      return { ...state, item: { ...state.item, status: 'unloaded', data: null } }

    case UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST:
      return { ...state, item: { ...state.item, updating: { status: 'loading' } } }

    case UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS:
      return { ...state, item: { ...state.item, updating: { status: 'loaded' } } }

    case UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR:
      return { ...state, item: { ...state.item, updating: { status: 'error' } } }
  }

  return state
}

export default reducer
