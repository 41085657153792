export const FETCH_ENTITY_REQUEST = 'FETCH_ENTITY_REQUEST'
export const FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS'
export const FETCH_ENTITY_ERROR = 'FETCH_ENTITY_ERROR'

export const FETCH_IMAGE_REQUEST = 'FETCH_IMAGE_REQUEST'
export const FETCH_IMAGE_SUCCESS = 'FETCH_IMAGE_SUCCESS'
export const FETCH_IMAGE_ERROR = 'FETCH_IMAGE_ERROR'

export const DELETE_ENTITY_REQUEST = 'DELETE_ENTITY_REQUEST'
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS'
export const DELETE_ENTITY_ERROR = 'DELETE_ENTITY_ERROR'

export const CLONE_ENTITY_REQUEST = 'CLONE_ENTITY_REQUEST'

export const CLONE_ENTITY_SUCCESS = 'CLONE_ENTITY_SUCCESS'
export const CLONE_ENTITY_ERROR = 'CLONE_ENTITY_ERROR'
export const CLONE_ENTITY_CLEAR = 'CLONE_ENTITY_CLEAR'

export const FETCH_ENTITIES_REQUEST = 'FETCH_ENTITIES_REQUEST'
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS'
export const FETCH_ENTITIES_ERROR = 'FETCH_ENTITIES_ERROR'

export const UPDATE_ENTITY_SEARCH_ENTITY = 'UPDATE_ENTITY_SEARCH_ENTITY'
export const CLEAR_ENTITY_SEARCH_ENTITY = 'CLEAR_ENTITY_SEARCH_ENTITY'

export const UPDATE_ENTITY = 'UPDATE_ENTITY'
export const UPDATE_ENTITY_METADATA = 'UPDATE_ENTITY_METADATA'

export const RESET_LOADED_ENTITY = 'RESET_LOADED_ENTITY'
