import { defaultState, IAuthState } from './state'
import { FETCH_USER_ERROR, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, RESET_AUTH } from './types'

const reducer = (state: IAuthState = defaultState, action: any): IAuthState => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoggedIn: false,
        user: null,

      }

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: action.user,
      }

    case FETCH_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
      }

    case RESET_AUTH:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
      }
  }

  return state
}

export default reducer
