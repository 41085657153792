import { Entity } from '@mpe/api-client/models/entities/entity'
import { Movie } from '@mpe/api-client/models/movie'
import personRoleOptions from '@mpe/api-client/config/personRoleOptions'
import entityLabelMap from '../config/entityLabelMap'
import stateOptions from '../config/stateOptions'

export const getMovieLabel = (entity: Movie) => {
  const seriesTitle = entity.seriesTitle ? ` - ${entity.seriesTitle}` : ''
  const seriesYear = entity.year ? ` (${entity.year})` : ''

  return `${entity.title}${seriesTitle}${seriesYear} (${entity.date})`
}

export const getEntityRoleLabels = (entity: Entity<any>): string | null => {
  return getRoleLabels(entity.role)
}

export const getRoleLabels = (value: string | null): string | null => {
  if (!value || value === '') {
    return null;
  }

  const roleValues = value.split(';');
  const roles = [];
  roleValues.forEach((roleItem) => {
    const item = personRoleOptions.find((optionItem: any) => optionItem.value === roleItem)
    if(item) roles.push(item.name);
  })

  if (roles.length === 0) {
    return null;
  }

  return roles.join(', ');
}

export const getEntityStateLabel = (entity: Entity<any>): string | null => {
  const state = stateOptions.find((stateItem: any) => entity.state === stateItem.value)

  if (!state) {
    return null
  }

  return state.name
}

export const getEntityTypeLabel = (
  value: string | string[] | null,
  labelType: 'singular' | 'plural' = 'plural'
) => {
  if (null === value || undefined === value) {
    return null
  }

  const type = value.toString()
  if (!type) {
    return null
  }

  if (entityLabelMap.hasOwnProperty(type)) {
    return typeof entityLabelMap[type] === 'string'
      ? entityLabelMap[type]
      : entityLabelMap[type][labelType];
  }

  return null;

}

export const getEntityLabel = (entity: Entity<any>) => {
  const dateFrom = entity.dateFrom ? ` (${entity.dateFrom})` : ''
  const dateFromOrTo = entity.dateFrom || entity.dateTo ? ` (${entity.dateFrom || ''} - ${entity.dateTo || ''})` : ''

  if ('Person' === entity.constructor.name) {
    const birthday = entity.birthday ? ` (${entity.birthday})` : ''

    return `${entity.name}${birthday}`
  }

  if ('Publication' === entity.constructor.name) {
    const publicationType = entity.publicationType ? ` - ${entity.publicationType} - ` : ''

    return `${entity.name}${publicationType}${dateFrom}`
  }

  if ('PublicationItem' === entity.constructor.name) {
    return `${entity.name}${dateFrom}`
  }

  if ('Institution' === entity.constructor.name) {
    return `${entity.name}${dateFromOrTo}`
  }

  if ('Venue' === entity.constructor.name) {
    return `${entity.name}${dateFromOrTo}`
  }

  if ('Event' === entity.constructor.name) {
    return `${entity.name}${dateFromOrTo}`
  }

  if ('EventSeries' === entity.constructor.name) {
    return `${entity.name}${dateFromOrTo}`
  }

  if ('MusicalPeriod' === entity.constructor.name) {
    return `${entity.name}${dateFromOrTo}`
  }

  if ('Band' === entity.constructor.name) {
    return `${entity.name}${dateFromOrTo}`
  }

  return entity.name
}

export const generateInitialFormValues = (entity: Entity<any> | null, defaultType?: string) => {
  if (entity && entity.changeRequest) {
    return generateInitialFormValuesFromEntityChangeRequest(entity)
  }

  if (entity) {
    return generateInitialFormValuesFromEntity(entity)
  }

  if (defaultType) {
    return {
      type: defaultType ?? '',
      state: 'pending',
      name: '',
      description: '',
      editorDescription: '',
      gptDescription: '',
    }
  }

  return {}
}

export const generateInitialFormValuesFromEntity = (entity: Entity<any>) => {
  const result = {
    ...entity,
    type: entity['@type'],
  }

  delete result.id
  delete result['@id']
  delete result['@type']

  return { ...result }
}

export const generateInitialFormValuesFromEntityChangeRequest = (entity: Entity<any>) => {
  const result = {
    ...entity.changeRequest,
    gptDescription: entity.gptDescription,
    type: entity['@type'],
  }

  delete result.id
  delete result['@id']
  delete result['@type']

  return { ...result }
}

export const getStringAsRichText = (original?: string): string | undefined => {
  if (!original) {
    return original;
  }

  if (!original.includes('<p>')) {
    return '<p>'+original.replace(/\n/g, '</p><p>')+'</p>';
  }

  return original;
}

export const getNumberOfSentences = (str: string, num: number): string => {
  // eslint-disable-next-line no-useless-escape
  const result = str.match(/[^\.!\?]+[\.!\?]+/g)
  return result?.slice(0, num).join(' ')
}

export const stripHtmlTags = (text: string): string => {
  return text.replace(/<\/?[^>]+(>|$)/g, '')
}

export const getNestedValue = (object, key: string): any => {
  const properties = Array.isArray(key) ? key : key.split('.');

  return properties.reduce((prev, curr) => prev?.[curr], object)
}
