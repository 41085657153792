import React from 'react'
import entityIcons from '../../config/entityIcons'
import { getEntityTypeLabel } from "../../utils/entityHelper";
import { Entity } from '@mpe/api-client/models/entities/entity'

interface Props {
  entity?: Entity<any>
  entityType?: string
  labelSingular?: boolean
}

class EntityIcon extends React.Component<Props> {
  render() {
    const type = this.props.entityType ?? this.props.entity?.type ?? null

    if (!type) {
      return null
    }

    const iconClass = entityIcons.hasOwnProperty(type) ? entityIcons[type] : null

    return (
      <i
        className={`fas ${iconClass} entity-color--${type.toLocaleLowerCase()}`}
        title={getEntityTypeLabel(type, this.props.labelSingular ? 'singular' : 'plural')}
      />
    )
  }
}

export default EntityIcon
