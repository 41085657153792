import { Image } from '@mpe/api-client/models/entities/image'
import { Audio } from '@mpe/api-client/models/entities/audio'

export interface IUploadFileProgress {
  item: File
  progress: {
    total: number
    percent: number
  }
  entity: Image | Audio | null
}

export interface IMediaUploaderState {
  uploadFiles: {
    items: IUploadFileProgress[]
  }
}

export const defaultState: IMediaUploaderState = {
  uploadFiles: {
    items: [],
  },
}
