import { Entity } from '@mpe/api-client/models/entities/entity'
import { Band } from "@mpe/api-client/models/entities/band";
import { defaultState, IEntityManagerState } from './state'
import {
  CLEAR_ENTITY_SEARCH_ENTITY,
  CLONE_ENTITY_ERROR,
  CLONE_ENTITY_REQUEST,
  CLONE_ENTITY_SUCCESS,
  CLONE_ENTITY_CLEAR,
  DELETE_ENTITY_SUCCESS,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITY_ERROR,
  FETCH_ENTITY_REQUEST,
  FETCH_ENTITY_SUCCESS,
  FETCH_IMAGE_ERROR,
  FETCH_IMAGE_REQUEST,
  FETCH_IMAGE_SUCCESS,
  RESET_LOADED_ENTITY,
  UPDATE_ENTITY,
  UPDATE_ENTITY_METADATA,
  UPDATE_ENTITY_SEARCH_ENTITY,
} from './types'
import {createEntityFromData} from "@mpe/api-client/utils/dataConverter";

const getFilteredEntities = (state: IEntityManagerState, iri: string) => {
  if (!state.collection.data || !state.collection.data.items) {
    return []
  }

  return state.collection.data.items.filter((collectionItem: Entity<any>) => collectionItem['@id'] !== iri)
}

const reducer = (state: IEntityManagerState = defaultState, action: any): IEntityManagerState => {
  switch (action.type) {
    case DELETE_ENTITY_SUCCESS:
      const items = getFilteredEntities(state, action.iri)

      return { ...state, collection: { ...state.collection, data: { ...state.collection.data, items: items } } }

    case FETCH_ENTITIES_REQUEST:
      return { ...state, collection: { status: 'loading', data: null } }

    case FETCH_ENTITIES_SUCCESS:
      return { ...state, collection: { status: 'loaded', data: { ...action.payload } } }

    case FETCH_ENTITIES_ERROR:
      return { ...state, collection: { status: 'error', data: null } }

    case FETCH_ENTITY_REQUEST:
      return { ...state, entity: { ...state.entity, status: 'loading', item: null } }

    case FETCH_ENTITY_SUCCESS:
      return { ...state, entity: { ...state.entity, status: 'loaded', item: action.item } }

    case FETCH_ENTITY_ERROR:
      return { ...state, entity: { ...state.entity, status: 'error', item: null } }

    case FETCH_IMAGE_REQUEST:
      return { ...state, imageEntity: { status: 'loading', item: null } }

    case FETCH_IMAGE_SUCCESS:
      return { ...state, imageEntity: { status: 'loaded', item: action.item } }

    case FETCH_IMAGE_ERROR:
      return { ...state, imageEntity: { status: 'error', item: null } }

    case UPDATE_ENTITY:
      return { ...state, entity: { ...state.entity, status: 'loaded', item: action.item } }

    case UPDATE_ENTITY_METADATA:
      const entity = { ...state.entity };
      if (entity.item && entity.item.type === 'Band') {
        const item: Band = createEntityFromData(entity.item) as Band;
        item.metadata = action.payload;
        entity.item = item;
      }

      return { ...state, entity }

    case RESET_LOADED_ENTITY:
      return {
        ...state,
        entity: { ...state.entity, status: 'unloaded', item: null },
        cloning: { status: 'unloaded' }
      }

    case UPDATE_ENTITY_SEARCH_ENTITY:
      return { ...state, entitySearch: { item: action.item } }

    case CLEAR_ENTITY_SEARCH_ENTITY:
      return { ...state, entitySearch: { item: null } }

    case CLONE_ENTITY_REQUEST:
      return { ...state, cloning: {
        status: 'cloning',
        item: null,
      }}

    case CLONE_ENTITY_SUCCESS:
      return { ...state, cloning: {
        status: 'cloned',
        item: action.payload,
      }}

    case CLONE_ENTITY_ERROR:
      return { ...state, cloning: {
        status: 'error',
        message: action.error.message || action.error.toString(),
      }}

    case CLONE_ENTITY_CLEAR:
      return { ...state, cloning: { status: 'unloaded' }}
  }

  return state
}

export default reducer
