import { Connection } from "@mpe/api-client/models/connections/connection";
import { ChangeRequestConnection } from "@mpe/api-client/models/change-request-connections/change-request-connection";

interface PageData {
  itemsTotal: number;
  pagesTotal: number;
  currentPage: number;
  orderField?: string;
  orderDir?: "desc" | "asc";
}

export interface IConnectionsState {
  mediaConnections: {
    status: string;
    items: Connection[];
    pageData?: PageData;
  };

  connections: {
    status: string;
    items: Connection[];
    pageData?: PageData;
  };

  changeRequestMediaConnections: {
    status: string;
    items: ChangeRequestConnection[];
    pageData?: PageData;
  };

  changeRequestConnections: {
    status: string;
    items: ChangeRequestConnection[];
    pageData?: PageData;
  };

  changeRequestConnectionsOrder: {
    status: string;
  };

  connectionEditor: {
    update: {
      status: string;
    };
    item: ChangeRequestConnection;
  };
}

export const defaultState: IConnectionsState = {
  mediaConnections: {
    status: "unloaded",
    items: [],
  },

  connections: {
    status: "unloaded",
    items: [],
  },

  changeRequestMediaConnections: {
    status: "unloaded",
    items: [],
  },

  changeRequestConnections: {
    status: "unloaded",
    items: [],
  },

  connectionEditor: {
    update: {
      status: "unloaded",
    },
    item: null,
  },

  changeRequestConnectionsOrder: {
    status: "unloaded",
  },
};
