import React from 'react'
import { withRouter } from 'next/router'

class Loader extends React.Component<any> {
  render() {
    return <div className={'spinner'+(this.props.wrapperClass ? ' '+this.props.wrapperClass : '')}>
      <div className={'spinner-wrapper text-center'+(this.props.spinnerClass ? ' '+this.props.spinnerClass : '')}>
        <div className={`spinner-border${this.props.className ? ` ${this.props.className}` : ''}`} role="status">
          <span className="sr-only">Betöltés...</span>
        </div>
        { this.props.label && <div className={`spinner-label text-muted small-text${this.props.labelClass ? ` ${this.props.labelClass}` : ' mt-4'}`}>{ this.props.label }</div> }
      </div>
    </div>;
  }
}

export default withRouter(Loader)
